<template>
  <div class="view view--light" style="background-color: inherit;">
    <v-row class="my-1" data-item-ignore>
      <v-col :cols="12" class="text-right py-0">
        <v-btn @click="toggleAside({ isClipped: true, isTemporary: false })" color="primary" text>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!customerAircraft" class="mt-4">
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <v-card v-if="customerAircraft" class="elevation-1 mb-4">
      <v-alert v-if="filteredItems.length === 0" text prominent type="info" border="left">
        {{ $t('hint.noData') }}
      </v-alert>

      <v-timeline v-else clipped dense align-top :reverse="isReverse" class="v-timeline--striped">
        <v-timeline-item
          v-for="(item, index) in filteredItems"
          :key="index"
          right
          small
          fill-dot
          truncate-line="end"
          :color="getItemColor(item)"
        >
          <div class="">
            <div class="flex flex-wrap">
              <div class="mr-6 font-weight-medium" :title="$d(new Date(item.date), 'short')">
                <v-icon class="mr-1" small>mdi-calendar</v-icon>
                {{ $d(new Date(item.date), 'my') }}
              </div>
              <div class="mr-4">
                <v-icon class="mr-1" small>mdi-airplane-clock</v-icon>
                {{ item.aircraft.time_since_new.split(':')[0] }} hr
              </div>
              <div>
                <v-chip small class="ma-1" v-for="tag in item.tags" :key="tag.id">
                  {{ tag.name }}
                </v-chip>
              </div>
            </div>

            <v-row justify="start" align="start" class="mt-1">
              <v-col cols="12" sm="4" lg="3" :title="item.maintainance_company.name">
                <v-icon class="mr-1" small>mdi-wrench</v-icon>
                {{ item.maintainance_company.alias }}
              </v-col>
              <v-col cols="12" sm="8" lg="3" class="flex align-center">
                <v-icon class="mr-1" small>mdi-account-circle</v-icon>
                <div>
                  {{ item.releasing_person.name }} -
                  <span v-if="item.releasing_person.number">#{{ item.releasing_person.number }}</span>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <p v-if="item.description && item.description.length > 60">{{ item.description.substring(0, 60) }}...</p>
                <p v-else>{{ item.description }}</p>
                <p v-if="item.certificate_release_service && item.certificate_release_service.id" class="mb-2">
                  <a
                    :href="getAssetUrl(item.certificate_release_service.id) + '?access_token=' + authToken"
                    target="_blank"
                    :title="item.certificate_release_service.title"
                  >
                    {{ item.certificate_release_service.title }}
                  </a>
                </p>
                <p v-if="item.order_number" class="mb-0">
                  <span>{{ $t('label.orderNumber') }}:</span>
                  {{ item.order_number }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card>

    <!-- Aside -->
    <v-navigation-drawer
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      v-model="aside.isVisible"
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn @click="aside.isVisible = false" icon depressed text> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>

      <div class="mx-3 my-3">
        <!-- Company -->
        <div>
          <v-autocomplete
            chips
            multiple
            small-chips
            clearable
            :label="$t('label.selectMaintainanceCompanies')"
            :items="availableMaintainanceCompanies"
            v-model="filters.maintainanceCompanies"
          ></v-autocomplete>
        </div>

        <!-- Date -->
        <div class="mt-2">
          <v-menu
            ref="menu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="$t('label.selectDateRange')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.dates" range no-title scrollable first-day-of-week="1" :show-current="lastEntryDate">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  filters.dates = [];
                  datePickerMenu = false;
                "
              >
                {{ $t('btn.cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(filters.dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <!-- Flight time -->
        <div>
          <v-autocomplete
            chips
            multiple
            small-chips
            clearable
            :label="$t('label.selectFlightTime')"
            :items="availableTimeSinceNew"
            v-model="filters.flightTimes"
          ></v-autocomplete>
        </div>

        <!-- Tags -->
        <div class="mt-2">
          <v-autocomplete
            chips
            multiple
            small-chips
            clearable
            :label="$t('label.selectTags')"
            :items="availableTags"
            v-model="filters.tags"
          ></v-autocomplete>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { getAssetUrl } from '@/services/utils';
import * as lItems from '@/shared/data/lifecycle.json';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';

export default {
  name: 'LifecycleIndex',

  components: {
    AlertNoAircraft
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`
    }),

    authToken() {
      return localStorage.getItem('auth_token') || '';
    },

    dateRangeText() {
      return this.filters.dates?.join(' - ');
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    availableMaintainanceCompanies() {
      return this.items.map(item => {
        return {
          value: item.maintainance_company.id,
          text: item.maintainance_company.alias
        };
      });
    },

    availableTimeSinceNew() {
      return this.items.map(item => {
        return {
          value: item.aircraft.time_since_new,
          text: item.aircraft.time_since_new
        };
      });
    },

    availableTags() {
      const result = [];
      this.items.forEach(item => {
        item.tags.forEach(tag => {
          if (!result.find(r => r.value === tag.id)) {
            result.push({
              value: tag.id,
              text: tag.name
            });
          }
        });
      });

      return result;
    },

    filteredItems() {
      // filter by maintainance company
      let result = this.items.filter(item => {
        if (this.filters.maintainanceCompanies.length === 0) {
          return true;
        }

        return this.filters.maintainanceCompanies.some(id => id === item.maintainance_company.id);
      });

      // filter by tags
      result = result.filter(item => {
        if (this.filters.tags.length === 0) {
          return true;
        }

        return this.filters.tags.some(id => item.tags.some(tag => tag.id === id));
      });

      // filter by date range
      result = result.filter(item => {
        if (this.filters.dates.length < 2) {
          return true;
        }

        const startDate = new Date(this.filters.dates[0]);
        const endDate = new Date(this.filters.dates[1]);
        const currentDate = new Date(item.date);

        return currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime();
      });

      // filter by flight time
      result = result.filter(item => {
        if (this.filters.flightTimes.length === 0) {
          return true;
        }

        return this.filters.flightTimes.some(time => time === item.aircraft.time_since_new);
      });

      // Sort by date
      result = result.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      return result;
    },

    lastEntryDate() {
      if (this.items.length === 0) {
        return true;
      }

      return this.items[this.items.length - 1].date.substring(0, 7);
    }
  },

  data() {
    return {
      itemColorMapping: {
        arc: 'red'
      },
      isReverse: false,
      items: lItems.default.slice(),
      aside: {
        isVisible: false,
        isClipped: true,
        isTemporary: false
      },
      filters: {
        dates: [],
        flightTimes: [],
        maintainanceCompanies: [],
        tags: []
      },
      datePickerMenu: false
    };
  },

  methods: {
    getAssetUrl,

    getItemColor(item) {
      let result = 'primary';

      if (item.tags.length > 0) {
        item.tags.forEach(tag => {
          if (this.itemColorMapping[tag.name.toLowerCase()]) {
            result = this.itemColorMapping[tag.name.toLowerCase()];
          }
        });
      }

      return result;
    },

    toggleAside(params = {}) {
      // reset aside settings
      const { component = '', isClipped = false, isTemporary = true } = params;
      this.aside.component = component;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;

      if (component === 'tableColumns') {
        this.aside.component = component;
        this.aside.isClipped = true;
        this.aside.isTemporary = false;
        this.aside.headline = this.$t('selectColumns');
      }

      this.aside.isVisible = !this.aside.isVisible;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-timeline--striped {
  padding-top: 0;

  &:before {
    z-index: 2;
  }
  .v-timeline-item {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    transition: box-shadow 0.2s ease-in-out;

    &:first-of-type {
      padding-top: 1.625rem;
    }

    &:nth-child(2n) {
      //background-color: var(--c-lightest-gray);
    }

    &:hover {
      box-shadow: 0 0 7px 5px rgba(var(--c-black-rgb), 0.05);
      position: relative;
    }
  }
}
</style>
